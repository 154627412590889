.form {
  /* text-align: center; */
}
.form .details {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.form .wrapper {
  font-family: ProductSans-Regular;
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 2px;
  width: 410px;
  margin-bottom: 25.5px;
}
.form .wrapper:nth-child(2n+1) {
  margin-right: 40px;

}
.form label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1px;
  color: #A1B8D5;
}
.form .wrapper > input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #A1B8D5;
  margin-bottom: 8.5px;
  font-size: 20px;
  color: #FFFFFF;
  height: 40px;
  border-radius: 0px;
  padding-bottom: 1px;
}

.form input:focus {
  outline: none;
  background: transparent;
}
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form input:-webkit-autofill:active {
  transition: background-color 1000s;
  -webkit-text-fill-color: #FFFFFF !important;
}

.checkbox-wrapper {
  display: flex;
  text-align: left;
}

.form .registerSelect {
  margin-bottom: 8.5px;
  font-size: 20px;
  color: #FFFFFF;
}
.form .registerSelect > div {
  border: none;
  border-bottom: 1px solid #A1B8D5;
  /* border-radius: 0px; */
  font-size: 20px;
  color: #FFFFFF;
}
.registerSelect .css-1dimb5e-singleValue {
  color: #FFFFFF!important;
}
.registerSelect > div > div:nth-child(1) > div {
  color: #2a4549;
}

.form .checkbox-wrapper {
  padding-top: 30px;
}
.form .checkbox {
  width: 25px !important;
  height: 25px !important;
  color: #FFFFFF !important;
  background-color: transparent !important;
  border: 1px solid #FFFFFF !important;
}
.form .agreement {
  font-family: ProductSans-Regular;
  font-size: 13px;
  line-height: 15px;
  color: #A1B8D5;
  letter-spacing: 1px;
  margin-left: 10px;
}
.form .is-small {
  display: inline-block;
  font-size: 10px;
  line-height: 13px !important;
  opacity: 0.5;
  margin-top: 9px;
  letter-spacing: 0.5px;
}

.mobile-version.is-small {
  display: none;
}

.btn-shannon.submit-btn-shannon {
  display: inline-block;
  background-color: #466A9A;
  padding: 11px 123px;
  color: #ffffff;
  margin-top: 55.5px;
  width: auto;
  border: none;
}
.submit-btn-shannon:hover {
  background-color: #2a4549;
  color: #ffffff;
}

.form .error {
  color: red;
}
 /*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/ 
@media (max-width: 1880px) {
  .form .wrapper {
    width: clamp(290px,20vw,410px);
  }
}
@media (max-width: 1399px) {
  .form {
    margin-top: 60px;
  }
  .form .wrapper {
    width: 440px;
    margin-bottom: 15px;

  }
}