.menu {
  background-color: #E5E3DF;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease ;
}
.menu.show {
  opacity: 1;
  visibility: visible;

}
.menu .left {
  height: 100vh;
  padding-left: 3.6vw!important;
}
.menu .nav-wrapper {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.menu .menu-text {
  margin-top: 29px;
  color: #3B3B3B;
}
.menu ul {
  padding-left: 0px;
  margin-bottom: 60px;
}
.menu li:not(:last-child) {
  margin-bottom: 13px;
}
.menu .nav {
  transition: opacity .2s ease-in;

}
.menu .btn-shannon.book-btn {
  border: 1px solid #3B3B3B;
  color: #3B3B3B;
  background: transparent;

}
.menu .btn-shannon.register-btn {
  margin-top: 15.5px;
}
.menu .nav.active {
  opacity: 0.35;
}

.menu .right {
  transition: opacity .2s ease-in;
}


@media (max-width: 767px) {
  .menu .menu-text {
    float: right;
  }
  .menu .left{
    padding: 0 6vw!important;
  }
  .menu .right {
    display: none;
  }
  .menu .cross-icon {
    width: 25.46px;
    height: 25.46px;
  }
  .nav {
    font-size: 26px;
    line-height: 38px;
  }
  .menu li:not(:last-child) {
    margin-bottom: 15px;
  }
  .menu ul {
    margin-bottom: 70px;
  }
  .menu .btn-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .menu .btn-shannon.register-btn{
    margin-top: 0;
  }
  .menu .btn-shannon.book-btn{
    margin-top: 15px;
    border: none;
    color: #FFFFFF;
    background: #466A9A;
  }

}