.header {
  padding:0 3.6vw ;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transform: translateY(0%);
  transition: all 0.4s;
  /* opacity: 1; */
}
.header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #FFFFFF;
  opacity: 0;
}
.header .logo {
  width: 237px;
  height: auto;
}
.header .logo-white .st0{
  fill:#FFFFFF;

}
.header .logo-black .st0{
  fill:#000000;
}

/* nav scroll */
.header.hide {
  color: #3B3B3B;
  opacity: 0;
  transform: translateY(-100%);
}
.header.hide,
.header.headerShow {
  background-color: #D8D5D1!important;
}
.header.hide .wrapper,
.header.headerShow .wrapper {
  border-bottom-color: #3B3B3B!important;
}
.header.hide .menu-text,
.header.headerShow .menu-text{
  color: #3B3B3B!important;
  /* color: #3B3B3B; */
}
.header.hide .logo-white .st0,
.header.headerShow .logo-white .st0{
  fill: #FFFFFF;
}
.header.hide .logo-black .st0,
.header.headerShow .logo-whiblackte .st0{
  fill: #3B3B3B;
}


.header .btn-shannon {
  height: 36px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 767px) {
  .header {
    padding: 0 6vw;
  }
  .header .logo {
    width: 185px!important;
  }
  
}

@media (max-width: 575px) {

}