.footer {
  background-color: #223957;
  padding-top: 80px;
  padding-bottom: 80px;
}
.footer .row {
  justify-content: space-between;
}
.footer .nav-address-wrapper {
  display:flex;
  justify-content: space-between;
}
.footer .nav-address-wrapper ul {
  padding-left: 0px;
  margin-bottom: 30px;
  margin-top: 60px;
}
.footer .nav,
.footer .nav-address-wrapper li {
  font-family: ProductSans-Regular;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #FFFFFF;
}
.footer .address-wrapper ul{
  margin-bottom: 60px;
}
.footer .btn-shannon {
  border: 1px solid #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
}
.footer .btn-shannon:hover {
  background-color: transparent;
  color: #FFFFFF;

}
.footer .row:last-child {
  margin-top: 125.5px;
  align-items: center;
}
.footer .logo-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 7.7vw;
}
.footer .landmark-logo {
  width: 97px;
  height: auto;
}
.footer .unik-logo {
  width: 116.16px;
  height: auto;
}
.footer .ace-logo {
  width: 127px;
  height: auto;
}
.footer .oakwyn-logo {
  width: 38.8px;
  height: auto;
}
.footer .copyright,
.footer .copyright a {
  font-family: ProductSans-Bold;
  font-size: 10px;
  line-height: 13px;
  /* letter-spacing: 2px; */
  letter-spacing: 1px;
  color: #A1B8D5;
  text-transform: uppercase;
}
.footer .row:last-child p {
  font-size: 10px;
  line-height: 12px;
  color: #A1B8D5;
  opacity: 0.5;
  margin-top: 10px;
}

/* form */
.footer .form-column h3 {
  margin-bottom: 30px;
}
.footer .form .wrapper,
.footer .form .registerSelect {
  margin-bottom: 5.5px;
}
.footer .form .checkbox {
  width: 25px !important;
}
.footer .btn-shannon.submit-btn-shannon {
  font-family: ProductSans-Bold;
  border: 1px solid #FFFFFF;
  background-color: transparent;
  padding: 11px 82px;
}
/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/

@media (max-width: 1880px) {
  .footer .logo-list {
    padding-right: 4.7vw;
  }
}
@media (max-width: 1700px) {
  .footer .logo-list {
    padding-right: 3vw;
  }

}
@media (max-width: 1399px) {
  .footer .row:first-child .right {
    display: none;
  }
  
.footer .landmark-logo {
  width: clamp(81px,5vw,97px);
}
.footer .unik-logo {
  width: clamp(97.04px,6.5vw,116.16px);
}
.footer .ace-logo {
  width: clamp(106.1px,7vw,127px);
}
.footer .oakwyn-logo {
  width: clamp(32.42px,2.3vw,38.8px);
}

}
@media (max-width: 1199px) {

}
@media (max-width: 991px) {
  .footer {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .nav-footer {
    display: none;
  }
  .footer .address-wrapper ul {
    margin-top: 40px;
    margin-bottom: 70px;
  }
  .footer .nav, .footer .nav-address-wrapper li {
    font-size: 18px;
  }
  .footer .row:last-child {
    flex-direction: column;
    align-items: normal!important;
    margin-top: 256px;
  }
  .footer .btn-shannon {
    border: 1px solid #466A9A;
    background-color: #466A9A;
  }
  .footer .logo-list {
    margin-bottom: 30px;
  }
  .footer .landmark-logo {
    width: 81px;
  }
  .footer .unik-logo {
    width: 97.04px;
  }
  .footer .ace-logo {
    width: 106.1px;
  }
  .footer .oakwyn-logo {
    width: 32.42px;
  }
  .footer .row:last-child p {
    line-height: 10px;
  }
}