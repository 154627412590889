
.popup-container {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
    transition: opacity 0.35s ease;
    animation: fadeIn 1.3s forwards;
}
.popup-container::before {
  position: absolute;
  content: "";
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background:rgba(0, 0, 0, 0.6);
  mix-blend-mode: multiply;
  z-index: 998;
  
}
.popup-container .wrapper {
  position:relative ;
  display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;

}

    /* Fade-in animation */
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
.popup {
  width: 600px;
  /* height: 615px; */
  background-color: #466A9A;
  padding: 20px;
  padding-bottom: 55px;
  text-align: center;
}  
.popup img {
  width: 30px;
  height: 30px;
}
.popup .cross {
  display: block;
  margin-left: auto;
  cursor: pointer;
}
.popup .logo {
  width: 205.76px;
  height: auto;
}
.popup .heading {
  font-size: 34px;
  line-height: 51px;
  color:#FFFFFF ;
  font-family: PPFragment-SerifRegular;
  margin: 70px 30px 20px;
  letter-spacing: -1px;
}
.popup p {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1px;
  color:#FFFFFF ;
}
.popup .time {
  margin: 30px 30px 40px;
  padding: 20px;
  font-family: PPFragment-SerifRegular;
  letter-spacing: -1px;
  font-size: 26px;
  line-height: 40px;
  color:#FFFFFF ;
  border-top: 1px solid #D8D5D1;
  border-bottom: 1px solid #D8D5D1;
}
.popup .book-btn {
  font-size: 17px;
  line-height: 20px;
  background-color: #3B3B3B;
  border: 1px solid #466A9A ;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 15px 0px;
  width: 300px;
  margin: 0 auto;
}
.popup .book-btn:hover {
  background-color: #3B3B3B;
  border: 1px solid #466A9A ;

}

@media (max-width: 1700px) {
  .popup {
    width: auto;
  }
  .popup .heading {
    font-size: clamp(26px,2.5vw,34px);
    line-height: clamp(38px,3.5vw,51px);
    margin-top: clamp(40px,5vw,70px);
  }
  .popup p {
    font-size: clamp(15px,1.2vw,18px);
    line-height: clamp(18px,1.5vw,22px);
  }
  .popup .time {
    font-size: clamp(22px,1.8vw,26px);
    line-height: clamp(36px,3vw,40px);
  } 


}   
@media (max-width: 1540px) {
  .popup {
    padding-bottom: 45px;
  }
  .popup .heading {
    font-size: 26px;
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .popup p {
    font-size: 15px;
    line-height: 18px;
  }
  .popup .time {
    font-size: 22px;
    line-height: 36px;
    margin: 20px 30px 30px;
    padding:15px;
  }
  .popup .cross {
    width: 20px;
    height: 20px;
  }

}

@media (max-width: 767px) {   
  .popup {
    padding-bottom: 70px;
  }
  .popup .heading {
    font-size: 26px;
    line-height: 38px;
    margin: 74px 15px 20px;
  }
  .popup p {
    font-size: 15px;
    line-height: 18px;
  }
  .popup .time {
    font-size: 22px;
    line-height: 36px;
    margin: 40px 30px 47px;
    padding:20px;
  }
  .popup .cross {
    width: 30px;
    height: 30px;
  }
}    
@media (max-width: 450px) {  
  .popup {
    margin: 0 6vw;
  }
  .popup .heading span{
    display: block;

  }
  .popup .cross {
    width: 20px;
    height: 20px;
  }
  .popup p {
    font-size: 12px;
    line-height: 16px;
  }
  .popup .time {
    font-size: 21px;
    padding: 20px 0px;
  }

}   