/* global style */
body {
  /* background-color: #2a4549; */
}
.page-wrapper {
  overflow: hidden;
}
img {
  width: 100%;
}

a {
  text-decoration: none;
}
ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.global-padding {
  padding-left: 3.6vw;
  padding-right: 3.6vw;
}

.is-flex {
  display: flex;
}

.is-relative {
  position: relative;
}
.is-absolute {
  position:absolute;
}

.container {
  max-width: 100%;
  padding: 0px;
}
.container .row {
  margin-left: 0;
  margin-right: 0;
}
.container .row > *{
  padding: 0;
}
.mobile-version767 {
  display: none!important;
}
.mobile-version991 {
  display: none!important;
}
/* lenis */
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* end lenis */

/*
|------------------------------------------------------
|  Typograph
|------------------------------------------------------
*/
@font-face {
  font-family: ProductSans-Bold;
  src: url(fonts/ProductSans-Bold.ttf);
}
@font-face {
  font-family: ProductSans-Regular;
  src: url(fonts/ProductSans-Regular.ttf);
}
@font-face {
  font-family: PPFragment-SerifRegular;
  src: url(fonts/PPFragment-SerifRegular.otf);
}
@font-face {
  font-family: PPFragment-SerifLightItalic;
  src: url(fonts/PPFragment-SerifLightItalic.otf);
}

h1,
h2,
.bedroom-nav {
  font-family: PPFragment-SerifRegular;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: -1px;
  color: #3B3B3B;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  margin-bottom: 0px;
}
h1 span:nth-child(2),
h2 span:nth-child(2) {
  font-family: PPFragment-SerifLightItalic;

}
h3 {
  font-family: ProductSans-Bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 2px;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 0px;

}
.subtitle {
  font-family: ProductSans-Bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 2px;
  color: #466A9A;
  text-transform: uppercase;
  margin-bottom: 40px;

}

p {
  font-family: ProductSans-Regular;
  color: #3B3B3B;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1px;
}
.photo-mark {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  color: #FFFFFF;
  position: absolute;
  bottom: 10px;
  right: 10px;
}



.btn-shannon {
  background-color: #466A9A;
  font-family: ProductSans-Bold;
  border-radius: 18px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1px;
  display: block;
  width: 218px;
  padding: 9px 0px;
  text-align: center;
  text-transform: uppercase;
}
.btn-shannon:hover {
  background-color: #466A9A;
  color: #FFFFFF;
}

.floorplans-btn {
  margin-top:15px; 
  border: 1px solid #3B3B3B;
  background-color: transparent;
  color: #3B3B3B;
}
.features-btn {
  padding: 11px 26px;
  width: fit-content;
  margin-top: 70px;
  margin-bottom: 35px;
}

.btn-shannon.all-townhomes {
  padding: 11px 66px;
  width: auto;
  display: inline-block;
}

.arrow {
  width: 5px;
  height: 11.5px;
}

/* nav */
.nav {
  font-family: PPFragment-SerifRegular;
  color: #3B3B3B;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: -1px;
  text-transform: capitalize;
}

.menu-text {
  font-family: PPFragment-SerifRegular;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -1px;
  text-transform: capitalize;
  margin: 0;
  padding: 0px;
  background-color: transparent;
  border: 0px;
  color: #FFFFFF;
}

/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {

  /* header */
  .menu-text {
    font-size: 20px;
    line-height: 29px;
  }
  .header .logo {
    width: clamp(205px,15vw,237px);
  }
  .header .btn-shannon {
    width: clamp(160px,13vw,218px);
  }
  .features-btn {
    margin-top: clamp(40px,3vw, 70px);
  }


}

@media (max-width: 991px) {
  .desktop-version991 {
    display: none!important;
  }
  .mobile-version991 {
    display: block!important;
  }

}

@media (max-width: 767px) {
  
  .global-padding {
    padding-left: 6vw;
    padding-right: 6vw;
  }
  .desktop-version767 {
    display: none!important;
  }
  .mobile-version767 {
    display: block!important;
  }
  /* Typograph */
  p { 
  font-size: 18px;
  line-height: 25px;
  }
  .photo-mark {
    font-size: 10px;
    line-height: 13px;
  }
  /* nav */
  .header .wrapper {
    flex-direction: row-reverse;
  }
  .header .btn-shannon {
    width: 130px;
    height: 40px;
    border-radius: 25px;
  }
  .rergister-burger .mobile-version767 {
    display: flex!important;
  }
  .menu-text {
    margin-left: 20px;
  }
  .rergister-burger .burger {
    align-items: center;
    justify-content: center;
  }
  .burger .line {
    width: 36px;
    height: 1px;
    background-color: #FFFFFF;
  }
  .burger .line:last-child {
    margin-top: 9px;
  }

  
}